<template>
    <div id="pushButtonAlertList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="pushButtonAlerts"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="alert_pushButtonAlertListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link
                                        v-if="!isEmptyList"
                                        :to="{ name: 'newPushButtonAlert' }"
                                        id="alerts_createNewAlertButton"
                                        class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10"
                                    >
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("alert_createNew") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->
                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="pushButtonAlerts" :siteId="siteId"></app-accesserrorportlet>

                        <div
                            v-if="accessGranted"
                            class="kt-content kt-grid__item kt-grid__item--fluid"
                        >
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div
                                        class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile"
                                    >
                                        <div
                                            class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm"
                                        >
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <path
                                                                d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                                                                fill="#000000"
                                                            />
                                                            <rect
                                                                fill="#000000"
                                                                opacity="0.3"
                                                                x="10"
                                                                y="16"
                                                                width="4"
                                                                height="4"
                                                                rx="2"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3
                                                    class="kt-portlet__head-title"
                                                >{{ $t("alert_pushButtonAlertList") }}</h3>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist
                                            targetObjectName="PUSHBUTTON_ALERT"
                                            mainTextKey="alert_pushButtonAlertNotFound"
                                            subTextKey="alert_pushButtonAlertNotFoundSubLabel"
                                            imgName="empty_notifications.svg"
                                            v-bind:isDisplayed="isEmptyList"
                                        ></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="alerts_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "./../utils/accesserrorportlet.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            sitePushButtonAlertsTable: null,
            isEmptyList: false,
            isMounted: false
        };
    },
    created: function() {
        console.log("Component(sitePushButtonAlerts)::created() - called");
        if (this.createAnotherPushButtonAlert) {
            this.$router.push({ name: "newPushButtonAlert" });
        } else {
            // Load user sites from backend and store the result in sites store.
            this.getSitePushButtonAlerts(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(sitePushButtonAlerts)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(sitePushButtonAlerts)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        sitePushButtonAlerts: function() {
            console.log("Component(sitePushButtonAlerts)::watch(sitePushButtonAlerts) called");
            this.initKtDataTable();
        },
        user: function(user) {
            if (user) {
                console.log("Component(sitePushButtonAlerts)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "sitePushButtonAlerts",
            "currentSite",
            "createAnotherPushButtonAlert",
            "user"
        ]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            }
            return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSitePushButtonAlerts", "showDeleteModal", "updatePushButtonAlertState"]),

        onEditPushButtonAlert: function(alertId) {
            this.$router.push({
                name: "editPushButtonAlert",
                params: { alertId: alertId, siteId: this.siteId }
            });
        },

        registerEventsOnSitePushButtonAlertsTable() {
            var self = this;

            $("#alerts_datatable").on("kt-datatable--on-layout-updated", () => {

                $(".onChangeStateRadio").off().on("change", function() {
                    let alertId = $(this).attr("data-alertid");
                    console.log("Component(sitePushButtonAlerts)::registerEventsOnSitePushButtonAlertsTable(onChangeStateRadio). AlertId = " + alertId);
                    self.updatePushButtonAlertState({
                        alertId: alertId,
                        siteId: self.siteId,
                        isActive: this.checked
                    });
                });

                $(".deleteAlertButton").off().on("click", function() {
                    let alertId = $(this).attr("data-alertid");
                    let alertName = $(this).attr("data-alertname");
                    console.log("Component(sitePushButtonAlerts)::registerEventsOnSitePushButtonAlertsTable(deleteAlertButton) clicked. AlertId = " + alertId);
                    self.showDeleteModal({
                        textContent: i18n.t("alert_deleteAlertConfirmText", {alertName: alertName}),
                        actionName: "DELETE_PUSHBUTTON_ALERT",
                        params: {
                            siteId: self.siteId,
                            alertId: alertId
                        }
                    });
                });

                $(".editPushButtonAlertButton").off().on("click", function() {
                    let alertId = $(this).attr("data-alertid");
                    console.log("Component(sitePushButtonAlerts)::registerEventsOnSitePushButtonAlertsTable(editPushButtonAlertButton) clicked. AlertId = " + alertId);
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    // Go to edit vue
                    self.onEditPushButtonAlert(alertId);
                });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);

            });

        },

        initKtDataTable() {
            if (this.sitePushButtonAlerts !== null) {
                // If site table has been already build, destroy the table before creating a fresh instance !
                if (this.sitePushButtonAlertsTable) {
                    $("#alerts_datatable").KTDatatable().destroy();
                    // After destroy set to null
                    this.sitePushButtonAlertsTable = null;
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.sitePushButtonAlerts.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;
                    // Prepare here the data of pushButtonAlert, this use to enable sort or filter on data which must displayed after i18n translation
                    const strStatusActive = i18n.t("alert_statusActive");
                    const strStatusInactive = i18n.t("alert_statusInactive");
                    let dataSource = [];
                    for (let pushButtonAlert of this.sitePushButtonAlerts) {
                        let alert = {...pushButtonAlert };
                        const eventTypeName = (pushButtonAlert.pushEventType && pushButtonAlert.pushEventType.name ? pushButtonAlert.pushEventType.name : "");
                        const isDefault = (pushButtonAlert.pushEventType && pushButtonAlert.pushEventType.isDefault ? true : false);
                        alert.status = (pushButtonAlert.isActive ? strStatusActive : strStatusInactive);
                        alert.eventTypeName = (isDefault ? i18n.t("alert_pushEventType_" + eventTypeName) : eventTypeName);
                        dataSource.push(alert);
                    }

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(dataSource),
                            pageSize: 10,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        columns: [
                            {
                                field: "name",
                                title: i18n.t("common_name"),
                                textAlign: "center",
                                template: function(row) {
                                    return (
                                        '\
                                        <div class="kt-user-card-v2">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="editPushButtonAlertButton kt-link kt-user-card-v2__name" data-alertid="' +
                                        row.id +
                                        '" href="javascript:;">' +
                                        row.name +
                                        "</a>\
                                            </div>\
                                        </div>\
                                    "
                                    );
                                }
                            },
                            {
                                field: "status",
                                title: i18n.t("alert_status"),
                                overflow: "visible",
                                textAlign: "center",
                                autoHide: false,
                                template: function(row) {
                                    if (row.isActive) {
                                        return (
                                            '<div style="margin-top:10px;"><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                            row.status +
                                            "</span>"+
                                            '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                                '<label>'+
                                                    '<input class="onChangeStateRadio" checked="checked" data-alertid="'+row.id+'" type="checkbox">'+
                                                    '<span></span>'+
                                                '</label>'+
                                            '</span></div>'
                                        );
                                    } else {
                                        return (
                                            '<div style="margin-top:10px;"><span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' +
                                            row.status +
                                            "</span>"+
                                            '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                                '<label>'+
                                                    '<input class="onChangeStateRadio" type="checkbox" data-alertid="'+row.id+'">'+
                                                    '<span></span>'+
                                                '</label>'+
                                            '</span></div>'
                                        );
                                    }
                                }
                            },
                            {
                                field: "eventTypeName",
                                title: i18n.t("alert_pushButtonAlertEventType"),
                                textAlign: "center"
                            },
                            {
                                field: "activationPeriod",
                                title: i18n.t("geo_notif_activePeriod"),
                                textAlign: "left",
                                width: 270,
                                sortable: false,
                                template: function(row) {
                                    if (row.startTime && row.endTime) {
                                        return (
                                            "<u>" +
                                            i18n.t("geo_notif_notificationFromStartTime") +
                                            "</u>: " +
                                            moment(row.startTime).format(
                                                commonVueHelper.getDateStringFormatFromLocale(
                                                    moment().locale()
                                                )
                                            ) +
                                            "<br>" +
                                            "<u>" +
                                            i18n.t("geo_notif_notificationToEndTime") +
                                            "</u>: " +
                                            moment(row.endTime).format(
                                                commonVueHelper.getDateStringFormatFromLocale(
                                                    moment().locale()
                                                )
                                            )
                                        );
                                    } else {
                                        return i18n.t( "geo_notif_notificationPeriodAlways");
                                    }
                                }
                            },
                            {
                                field: "methods",
                                title: i18n.t("alert_pushButtonAlertNotificationMethods"),
                                textAlign: "left",
                                sortable: false,
                                template: function(row) {
                                    var html = "<ul style='margin-bottom: 0px;list-style: inside;'>";
                                    if (row.pushButtonNotificationTypes) {
                                        for (var notificationType of row.pushButtonNotificationTypes) {
                                            html +=
                                                "<li>" +
                                                i18n.t("geo_notif_" + notificationType.pushButtonNotificationType.name) +
                                                "</li>";
                                        }
                                    }
                                    html += "</ul>";
                                    return html;
                                }
                            },
                            {
                                field: "Actions",
                                title: i18n.t("common_actions"),
                                sortable: false,
                                width: 110,
                                overflow: "visible",
                                autoHide: false,
                                textAlign: "center",
                                template: function(row) {
                                    return (
                                        `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-alertid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editPushButtonAlertButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_delete") +
                                        `" type="button" data-alertid="` +
                                        row.id +
                                        `" data-alertname="` +
                                        row.name +
                                        `" class="btn btn-outline-danger btn-sm btn-icon deleteAlertButton">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                    );
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.sitePushButtonAlertsTable = $("#alerts_datatable").KTDatatable(options);
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnSitePushButtonAlertsTable();
                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
